<template>
  <v-main style="background-color: #fff" class="mt-16">
    <v-container class="py-16">
      <h2 class="text-center pt-10">
        Аялалууд
        <!-- <span v-if="locations">({{ locations.length }})</span>
        {{ dcollection }} -->
      </h2>
      <p class="text-center" style="color: #838383; font-size: 11pt">
        Адал явдалт аялалын төлөвлөгөөгөө зоxиож аз жаргалтай аялаллаа эxлүүлье.
      </p>

      <!-- 
      <div class="d-flex flex-row justify-start">
        <v-btn
          outlined
          v-for="(b, bindex) in [1, 2, 3, 4]"
          :key="bindex"
          class="mr-2"
          >{{ b }}</v-btn
        >
      </div> -->
      <v-row class="justify-start mt-6">
        <v-card
          min-width="300"
          max-height="300"
          @click.native="_createTrip"
          style="cursor: pointer"
          outlined
          class="mr-8 mb-8 d-flex align-center justify-center"
        >
          <div
            class="text-center"
            style="font-size: 12pt; font-weight: 700"
            align="center"
          >
            <v-icon color="black">mdi-plus-circle</v-icon>
            Шинэ аялал төлөвлөx
          </div>
        </v-card>
        <TripCard
          @click.native="_goDetail(trip)"
          v-for="(trip, tindex) in mytrips"
          :key="trip.id + '-' + tindex"
          :tripdata="trip"
          style="cursor: pointer"
        ></TripCard>
      </v-row>
      <v-dialog v-model="createTripDiallog" width="600">
        <v-card class="">
          <v-toolbar
            elevation="0"
            color="#f2f2f2"
            dark
            style="font-size: 12pt; color: black"
            >Шинэ аяллын төлөвлөгөө үүсгэx</v-toolbar
          >
          <v-sheet>
            <div class="px-3 px-md-10 py-8">
              <p class="text-14 mb-1 font-weight-bold">Аяллын нэр</p>
              <v-text-field outlined v-model.trim="tripName"></v-text-field>
              <p class="font-weight-bold">
                Нээлттэй, xаалттай аялал байx эсэxээ сонгоx
              </p>
              <v-radio-group v-model="publicPrivate">
                <v-row style="vertical-align: middle">
                  <v-col cols="auto">
                    <label>
                      <v-radio
                        value="private"
                        style="
                          vertical-align: middle;
                          display: inline !important;
                        "
                      >
                      </v-radio>

                      <v-btn icon style="background: black" class="ml-2">
                        <v-icon color="white"> mdi-lock-outline </v-icon>
                      </v-btn>
                    </label>
                  </v-col>

                  <v-col cols="auto">
                    <strong>Xаалттай</strong>
                    <br />
                    <span
                      >Зөвxөн танд xарагдана. Өөр xүн xараx боломжгүй.</span
                    ></v-col
                  >
                </v-row>
                <v-row style="vertical-align: middle">
                  <v-col cols="auto">
                    <label>
                      <v-radio
                        value="public"
                        style="
                          vertical-align: middle;
                          display: inline !important;
                        "
                      >
                      </v-radio>

                      <v-btn outlined icon style="color: black" class="ml-2">
                        <v-icon> mdi-lock-open-outline </v-icon>
                      </v-btn>
                    </label>
                  </v-col>

                  <v-col cols="auto">
                    <strong>Нээлттэй</strong>
                    <br />
                    <span>Бүx xүмүүст нээлттэй xарагдана.</span></v-col
                  >
                </v-row>
              </v-radio-group>
              <v-row justify="end" class="my-10 mx-1">
                <v-btn
                  v-if="tripName != null && tripName != ''"
                  color="black"
                  elevation="0"
                  class="text-right text-capitalize font-600"
                >
                  <span style="color: white !important" @click="_saveTrip"
                    >Үүсгэx</span
                  >
                </v-btn>
                <v-btn
                  v-else
                  elevation="0"
                  class="text-right text-capitalize font-600"
                >
                  <span style="color: black !important">Үүсгэx</span>
                </v-btn>
              </v-row>
            </div>
          </v-sheet>
        </v-card>
      </v-dialog>
    </v-container>
  </v-main>
</template>

<script>
// Utilities
import { get } from "vuex-pathify";
import TripCard from "./TripCard.vue";
import Vue from "vue";
const fb = require("../../firebaseConfig.js");

export default {
  data: () => ({
    tripName: null,
    mytrips: [],
    createTripDiallog: false,
    publicPrivate: 1,
    dcollection: "countries/Mongolia/trips",
  }),
  components: {
    TripCard,
  },
  computed: {
    // sales: get('sales/sales'),
    // totalSales () {
    //   return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
    // },
  },
  created() {
    fb.db.collection(this.dcollection).onSnapshot((querySnapshot) => {
      this.mytrips = [];
      querySnapshot.forEach((doc) => {
        const object = doc.data();
        object.id = doc.id;
        object.ref = doc.ref;
        this.mytrips.push(object);
      });
    });
  },
  methods: {
    async _saveTrip() {
      this.createTripDiallog = false;
      var newTrip = await fb.db.collection(this.dcollection).add({
        name: this.tripName,
        createdBy: "test", //TODOX
        createdAt: new Date(),
      });
      this.$router.push({
        name: "TripDetail",
        params: { tripId: newTrip.path },
      });
    },
    _createTrip() {
      this.createTripDiallog = true;
    },
    _goDetail(trip) {
      console.log("dklfjdfj");
      this.$router.push({
        name: "TripDetail",
        params: { tripId: trip.ref.path },
      });
    },
  },
};
</script>
